import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SelectIconDirective } from './select-icon.directive';
import { NavigateDirective } from './navigate.directive';
import { UserPermissionDirective } from './user-permission.directive';
import { NotUserPermissionDirective } from './not-user-permission.directive';
import { DisableFormIfNotPermissionDirective } from './disable-form-if-not-permission.directive';

@NgModule({
	declarations: [
		UserPermissionDirective,
		NotUserPermissionDirective,
		SelectIconDirective,
		NavigateDirective,
		DisableFormIfNotPermissionDirective,
	],
	imports: [CommonModule],
	exports: [
		UserPermissionDirective,
		NotUserPermissionDirective,
		SelectIconDirective,
		NavigateDirective,
		DisableFormIfNotPermissionDirective,
	],
})
export class DirectivesModule {}
