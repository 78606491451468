import { Injectable } from '@angular/core';
import { ILoginCredentials } from '@shared-libs/interfaces';
import { NavController } from '@ionic/angular';
import { UserManager } from '@shared-managers/user.manager';
import { StorageService } from '@shared-services/storage.service';
import { BackgroundColorService } from '@shared-services/background-color.service';
import { NavigationService } from '@shared-services/navigation.service';
import { ApplicationModule, NavigationType } from '@shared-libs/enums';
import { UserDetailsManager } from '@shared-managers/user-details.manager';
import { IUser } from '@shared-models/user.model';
import { SharedUserProvider } from '@shared-providers/api/user.provider';
import jwt_decode from 'jwt-decode';
import { StateManager } from '@shared-managers/state.manager';
import { NavigationDataManager } from '@shared-managers/navigation-data.manager';

/**
 * The authentication service to handle authentication logic
 */
@Injectable({
	providedIn: 'root',
})
export class AuthenticationService {
	private authenticated: boolean;

	constructor(
		private readonly loginProvider: SharedUserProvider,
		private readonly navController: NavController,
		private readonly userDetailsManager: UserDetailsManager,
		private readonly userManager: UserManager,
		private readonly storageService: StorageService,
		private readonly backgroundColorService: BackgroundColorService,
		private readonly navigationService: NavigationService,
		private readonly stateManager: StateManager,
		private readonly navigationDataManager: NavigationDataManager
	) {}

	/**
	 * Login, i.e. get a token from the api to authenticate future requests
	 *
	 * When a user is logged in, the user object is added the the user manager
	 * and saved if the user wants to keep being logged in.
	 * @param credentials The login credentials as {@link ILoginCredentials}
	 */
	public async login(credentials: ILoginCredentials): Promise<void> {
		const data = await this.loginProvider.login(credentials);
		const user: IUser = jwt_decode(data.access_token);
		this.userManager.setUser(user);
		this.userManager.setToken(data.access_token);
		this.userManager.setRefreshToken(data.refresh_token);

		if (credentials.keepMeLoggedIn) {
			this.userManager.saveUser();
			this.userManager.saveToken();
			this.userManager.saveRefreshToken();
		}

		this.authenticated = true;
		this.navigateToApplicationModule(ApplicationModule.Internal);
	}

	/**
	 * Navigate to application module
	 * @param module The module to navigate to
	 */
	public navigateToApplicationModule(module: ApplicationModule): void {
		void this.navigationService.navigateTo(NavigationType.page, module).catch();
	}

	/**
	 * Logout the current user
	 *
	 *  All user and application data is removed from session and persistent storage
	 */
	public logout(): void {
		this.userManager.removeUser();
		this.userDetailsManager.removeUserDetails();
		this.backgroundColorService.removeBackgroundColor();
		this.authenticated = false;
		void this.navController.navigateBack('login', { animated: false }).catch();
		setTimeout(() => {
			this.storageService.clearAll();
			this.navigationDataManager.flush();
			this.stateManager.flush();
		}, 1000);
	}

	/**
	 * A function used by the router authguard to make sure a user is authenticated when trying to access an url
	 * @returns Whether the user is authenticated
	 */
	public isAuthenticated(): Promise<boolean> {
		return new Promise((resolve) => {
			this.userManager.isAuthenticated().subscribe(
				async () => {
					this.authenticated = true;
					resolve(this.authenticated);
				},
				() => {
					this.authenticated = false;
					resolve(this.authenticated);
				}
			);
		});
	}
}
