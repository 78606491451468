import { init as initSentry, routingInstrumentation, Event } from '@sentry//angular-ivy';
import { BrowserTracing } from '@sentry/tracing';
import { environment as ENV } from '@environments/environment';
import version from '@root/src/version.json';
import { Environment } from '@shared-libs/enums';

/**
 * Get the current app environment
 * @returns {Environment} The current app environment
 */
const getEnvironment = (): Environment => {
	if (location.href.includes('localhost')) {
		return Environment.Local;
	} else if (location.href.includes('staging')) {
		return Environment.Staging;
	} else if (location.href.includes('acceptance')) {
		return Environment.Acceptance;
	}
	return Environment.Production;
};

export const initializeSentry = (): void => {
	initSentry({
		dsn: 'https://14bf33de644240588787c2a5336812dc@sentry.beego.be/20',
		environment: getEnvironment(),
		release: `digiportal-app@${version.version}`,
		normalizeDepth: 10,
		integrations: [
			new BrowserTracing({
				tracingOrigins: [
					'localhost',
					ENV.BASE_URL,
					'https://digiportal-backend-acceptance.herokuapp.com',
					'https://digiportal-backend-staging.herokuapp.com',
					'https://digiportal-backend-production.herokuapp.com',
				],
				routingInstrumentation,
			}),
		],
		tracesSampleRate: 0.1,
		beforeSend: (event): Event => {
			// Filter out backend errors, because they are logged in the back-end sentry
			if (
				((event?.extra?.__serialized__ as any)?.name === 'HttpErrorResponse' &&
					((event?.extra?.__serialized__ as any)?.url as string)?.includes(ENV.BASE_URL)) ||
				((event?.extra?.__serialized__ as any)?.url as string)?.includes(location.hostname) ||
				event?.message?.includes(location.hostname) ||
				(event as any)?.url?.includes(location.hostname)
			) {
				return null;
			}
			return event;
		},
	});
};
