import { Injectable } from '@angular/core';
import { StorageService } from '@shared-services/storage.service';
import { StorageKey, UserPermissions } from '@shared-libs/enums';
import { IUser } from '@shared/models/user.model';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { difference } from 'lodash';
import jwt_decode from 'jwt-decode';

/* TODO: replace managers with redux strategy? */

/**
 * The manager for the user object. A Manager is responsible for the state and reusability (across the application) of certain properties
 * @see {@link IUser}
 */
@Injectable({
	providedIn: 'root',
})
export class UserManager {
	private user: IUser;
	private token: string;
	private refreshToken: string;
	private permissionsSubject: BehaviorSubject<UserPermissions[]>;

	constructor(private readonly storageService: StorageService) {
		this.initPermissionsSubject();
	}

	/**
	 * Set the user object in session storage
	 * @param user The user that is set
	 */
	public setUser(user: IUser): void {
		this.user = user;
		this.permissionsSubject.next(this.user.permissions);
	}

	/**
	 * Save the user currently in session storage to persistent storage
	 * @param user The user
	 */
	public saveUser(user?: IUser): void {
		if (user) {
			this.user = user;
			this.permissionsSubject.next(this.user.permissions);
		}
		if (this.user) {
			this.storageService.setItem(StorageKey.user, this.user).subscribe();
		}
	}

	/**
	 * Get the user, firstly from session storage and secondly from persistent storage
	 * @returns An observable that returns a {@link IUser}
	 */
	public getUser(): Observable<IUser> {
		return new Observable((subscriber) => {
			if (this.user) {
				subscriber.next(this.user);
			} else {
				forkJoin({
					user: this.storageService.getItem<IUser>(StorageKey.user),
					token: this.storageService.getItem<string>(StorageKey.token),
				}).subscribe({
					next: ({ user, token }) => {
						if (user && token) {
							const tokenUser: IUser = jwt_decode(token);
							this.setUser(tokenUser);
							this.saveUser(tokenUser);
							subscriber.next(tokenUser);
						} else subscriber.error();
					},
					error: (error) => subscriber.error(error),
				});
			}
		});
	}

	/**
	 * Remove the user from session and persistent storage
	 */
	public removeUser(): void {
		this.user = null;
		this.token = null;
		this.refreshToken = null;
		this.storageService.removeItem(StorageKey.user).subscribe();
		this.storageService.removeItem(StorageKey.token).subscribe();
		this.storageService.removeItem(StorageKey.refreshToken).subscribe();
	}

	/**
	 * Get the api token of the current user.
	 * It is used as authorization method for api requests.
	 * @returns The api token
	 */
	public getToken(): string {
		return this.token;
	}

	/**
	 * Set the token of the current user in session storage
	 * @param token A new token for the user
	 */
	public setToken(token: string): void {
		this.token = token;
	}

	/**
	 * Set the token of the current user in persistent storage
	 * @param token A new token for the user
	 */
	public saveToken(token?: string): void {
		if (token) {
			this.token = token;
			const tokenUser: IUser = jwt_decode(token);
			this.setUser(tokenUser);
			this.saveUser(tokenUser);
		}
		if (this.token) {
			this.storageService.setItem(StorageKey.token, this.token).subscribe(() => {});
		}
	}

	/**
	 * Get the refresh token of the current user.
	 * It is used to get a new token when it is expired
	 * @returns The refresh token
	 */
	public getRefreshToken(): string {
		return this.refreshToken;
	}

	/**
	 * Set the refresh token of the current user in session storage
	 * It is used to get a new token when it is expired
	 * @param token The refresh token
	 */
	public setRefreshToken(token: string): void {
		this.refreshToken = token;
	}

	/**
	 * Set the refresh token of the current user in persistent storage
	 * @param token The refresh token
	 */
	public saveRefreshToken(token?: string): void {
		if (token) {
			this.refreshToken = token;
		}
		if (this.refreshToken) {
			this.storageService.setItem(StorageKey.refreshToken, this.refreshToken).subscribe(() => {});
		}
	}

	/**
	 * Get the user id of the current user
	 * @returns The user id
	 */
	public getUserId(): string {
		return this.user?.sub;
	}

	/**
	 * Get the user email of the current user
	 * @returns The user email
	 */
	public getUserEmail(): string {
		return this.user?.email;
	}

	/**
	 * Get the subject of the user permissions
	 * @returns A subject firing when the user permissions change
	 */
	public getUserPermissionsSubject(): BehaviorSubject<UserPermissions[]> {
		return this.permissionsSubject;
	}

	/**
	 * A check if a user has all required permissions
	 * @param requiredPermissions The required permissions
	 * @returns A boolean, whether or not the user has all the required permissions
	 */
	public hasAllPermissions(requiredPermissions: UserPermissions[]): boolean {
		if (!requiredPermissions?.length) {
			throw new Error('Required permissions should not be undefined');
		}

		return difference(requiredPermissions, this.user?.permissions ?? []).length === 0;
	}

	/**
	 * A check if a user does not have all required permissions
	 * @param requiredPermissions The required permissions
	 * @returns A boolean, whether or not the user does not have all the required permissions
	 */
	public hasNotAllPermissions(requiredPermissions: UserPermissions[]): boolean {
		if (!requiredPermissions?.length) {
			throw new Error('Required permissions should not be undefined');
		}

		return difference(requiredPermissions, this.user?.permissions ?? []).length !== 0;
	}

	/**
	 * A check whether the user is authenticated
	 * When true, it returns the user
	 * @returns Whether or not the user is authenticated
	 */
	public isAuthenticated(): Observable<IUser> {
		return new Observable((subscriber) => {
			if (this.user) {
				subscriber.next(this.user);
			} else {
				return forkJoin({
					user: this.storageService.getItem<IUser>(StorageKey.user),
					token: this.storageService.getItem<string>(StorageKey.token),
					refreshToken: this.storageService.getItem<string>(StorageKey.refreshToken),
				}).subscribe({
					next: ({ user, token, refreshToken }) => {
						if (user && token && refreshToken) {
							const tokenUser: IUser = jwt_decode(token);
							this.setUser(tokenUser);
							this.saveUser(tokenUser);
							this.setToken(token);
							this.setRefreshToken(refreshToken);
							subscriber.next(tokenUser);
						} else subscriber.error();
					},
					error: (error) => subscriber.error(error),
				});
			}
		});
	}

	private initPermissionsSubject(): void {
		if (!this.permissionsSubject) {
			this.permissionsSubject = new BehaviorSubject([]);
		}
	}
}
