import { APP_INITIALIZER, ErrorHandler, Injector, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { LocationStrategy, registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localeBE from '@angular/common/locales/nl-BE';
import { PathPreserveQueryLocationStrategy } from '@shared-libs/query-params.location-strategy';
import { Interceptor } from '@shared-providers/interceptor';
import * as Sentry from '@sentry/angular-ivy';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpLoaderFactory } from '@shared-factories/http-loader.factory';
import { SharedModule } from '@shared/shared.module';
import { FormColorModule, FormDateModule, FormTextareaModule } from '@beego/ngx-form-components';
import { QuillModule } from 'ngx-quill';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';

registerLocaleData(localeBE);

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		QuillModule.forRoot(),
		IonicModule.forRoot({ animated: false }),
		TranslateModule.forRoot({
			defaultLanguage: 'nl-BE',
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient],
			},
		}),
		AppRoutingModule,
		HttpClientModule,
		SharedModule,
		FormTextareaModule,
		FormDateModule,
		FormColorModule,
		LeafletModule,
	],
	providers: [
		{ provide: ErrorHandler, useValue: Sentry.createErrorHandler() },
		{ provide: Sentry.TraceService, deps: [Router] },
		{ provide: APP_INITIALIZER, useFactory: () => () => {}, deps: [Sentry.TraceService], multi: true },
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
		{ provide: LocationStrategy, useClass: PathPreserveQueryLocationStrategy },
		{ provide: LOCALE_ID, useValue: 'nl-BE' },
		{ provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true },
		HttpClient,
	],
	bootstrap: [AppComponent],
})
export class AppModule {
	/**
	 * Allows for retrieving singletons using `AppModule.injector.get(MyService)`
	 */
	static injector: Injector;

	constructor(private readonly injector: Injector) {
		AppModule.injector = this.injector;
	}
}
