import { Component } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';
import { IApiError } from '@shared-libs/interfaces';

@Component({
	selector: 'error',
	templateUrl: 'error.component.html',
	styleUrls: ['./error.component.scss'],
})
export class ErrorComponent {
	public readonly error: IApiError;
	public readonly isValidation: boolean;

	constructor(
		public readonly navParams: NavParams,
		public readonly popoverController: PopoverController
	) {
		this.error = this.navParams.data.error;
		this.isValidation = this.navParams.data.isValidation;
	}

	public close(): void {
		void this.popoverController.dismiss().catch();
	}
}
