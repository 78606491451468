import { Api } from '@shared-providers/api.provider';
import { IChangePasswordCredentials, ILoginCredentials } from '@shared-libs/interfaces';
import { Injectable } from '@angular/core';
import { Provider } from '@shared-providers/Provider';
import { LoadingService } from '@shared-services/loading.service';
import { IUser, IUserDetails } from '@shared/models/user.model';

@Injectable({
	providedIn: 'root',
})
export class SharedUserProvider extends Provider<IUser> {
	protected readonly url: string = '/users';

	constructor(
		protected readonly api: Api,
		protected readonly loadingService: LoadingService
	) {
		super(loadingService);
	}

	public login(credentials: ILoginCredentials): Promise<{ access_token: string; refresh_token: string }> {
		return this.process(this.api.post<any>(`${this.url}/login`, credentials));
	}

	public updatePassword(credentials: IChangePasswordCredentials): Promise<void> {
		return this.process(this.api.post<any>(`${this.url}/update-password`, credentials));
	}

	public isActive(): Promise<boolean> {
		return this.process(this.api.get(`${this.url}/active`)) as Promise<boolean>;
	}

	public getDetails(): Promise<IUserDetails> {
		return this.process(this.api.get(`${this.url}/details`)) as Promise<IUserDetails>;
	}
}
