import { Injectable } from '@angular/core';
import { StorageService } from '@shared-services/storage.service';
import { Environment, MessageBoxKey, StorageKey } from '@shared-libs/enums';
import { Meta } from '@angular/platform-browser';
import { firstValueFrom } from 'rxjs/internal/firstValueFrom';

/* TODO: replace managers with redux strategy? */

/**
 * The general manager for the application. A Manager is responsible for the state and reusability (accross the application) of certain properties
 */
@Injectable({
	providedIn: 'root',
})
export class AppManager {
	/**
	 * Property that stores whether the titles of the navigation is shown.
	 * It comes down to the state of the menu.
	 */
	private menuIsOpen: boolean = true;

	constructor(
		private readonly storageService: StorageService,
		private readonly meta: Meta
	) {}

	/**
	 * Get the current version of the application, defined in package.json
	 * @returns {string} The version of the application
	 */
	public getVersion(): string {
		return this.meta.getTag('name=version')?.content;
	}

	/**
	 * Set the current state of the menu and stores it in persistent storage
	 * @param menuIsOpen boolean that defines if the menu is open or not
	 */
	public setMenuState(menuIsOpen: boolean): void {
		this.menuIsOpen = menuIsOpen;
		this.storageService.setItem(StorageKey.menu, this.menuIsOpen).subscribe();
	}

	/**
	 * Loads the menu state from persistent storage
	 */
	public loadMenuState(): void {
		this.storageService.getItem<boolean>(StorageKey.menu).subscribe(
			(menuIsOpen) => {
				this.menuIsOpen = menuIsOpen;
			},
			() => {}
		);
	}

	/**
	 * Get the current state of the menu.
	 * @returns {boolean} Whether the menu is open or not
	 */
	public getMenuState(): boolean {
		return this.menuIsOpen;
	}

	/**
	 * Loads the state of a message box from persistent storage
	 * @param key identifier of the messagebox in persistent storage
	 * @returns a promise with a boolean
	 */
	public async getMessageBoxState(key: MessageBoxKey): Promise<boolean> {
		return await firstValueFrom(this.storageService.getItem(key));
	}

	/**
	 * Set the state of a message box to persistent storage
	 * @param key identifier of the messagebox in persistent storage
	 * @param closed boolean that indicates if the selected messagebox has been closed or not
	 */
	public setMessageBoxState(key: MessageBoxKey, closed: boolean): void {
		this.storageService.setItem(key, closed).subscribe();
	}

	/**
	 * Get the current app environment
	 * @returns {Environment} The current app environment
	 */
	public getEnvironment(): Environment {
		if (location.href.includes('localhost')) {
			return Environment.Local;
		} else if (location.href.includes('staging')) {
			return Environment.Staging;
		} else if (location.href.includes('acceptance')) {
			return Environment.Acceptance;
		}
		return Environment.Production;
	}

	/**
	 * Get whether the app is not in a production environment
	 * @returns {boolean} Whether the app is not in production or in production
	 */
	public isNotProduction(): boolean {
		return this.getEnvironment() !== Environment.Production;
	}
}
