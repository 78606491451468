import { Component, HostListener } from '@angular/core';

import { Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AppManager } from '@app/modules/shared/managers/app.manager';
import { BackgroundColorService } from '@app/modules/shared/services/background-color.service';
import { NavigationService } from '@app/modules/shared/services/navigation.service';
import { NavigationType } from '@shared/libs/enums';
import { Router } from '@angular/router';

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss'],
})
export class AppComponent {
	constructor(
		private readonly platform: Platform,
		private readonly translateService: TranslateService,
		private readonly appManager: AppManager,
		private readonly backgroundColorService: BackgroundColorService,
		private readonly navigationService: NavigationService,
		private readonly router: Router
	) {
		this.initializeApp();
	}

	@HostListener('ionModalWillPresent')
	ionModalWillPresent(): void {
		this.backgroundColorService.resetBackgroundColor();
	}

	@HostListener('ionModalWillDismiss')
	ionModalWillDismiss(): void {
		this.backgroundColorService.setPreviousBackgroundColor();
	}

	private initializeApp(): void {
		this.translateService.use('nl-BE');
		void this.platform
			.ready()
			.then(() => {
				this.appManager.loadMenuState();
				this.navigationService.addToHistory(NavigationType.page, this.router.url, null);
				setTimeout(() => {
					this.navigationService.addToHistory(NavigationType.page, this.router.url, null);
				}, 100);
			})
			.catch();
	}
}
