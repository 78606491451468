import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, NavController, PopoverController } from '@ionic/angular';
import { ApplicationModule, NavigationDataKey, NavigationType } from '@shared-libs/enums';
import { INavigationObject, INavigationOptions } from '@shared-libs/interfaces';
import { NavigationDataManager } from '@app/modules/shared/managers/navigation-data.manager';
import { cloneDeep, findLast } from 'lodash';
import { UserManager } from '@shared-managers/user.manager';
import { BehaviorSubject } from 'rxjs';
import { ComponentRef } from '@ionic/core';

const baseNavigationOptions: INavigationOptions = {
	addToHistory: true,
	closePreviousModals: true,
};

/** Importing these from the routes breaks the application */
const publicRoutes = ['/internal', '/login', '/register', '/digibank-registreren', '/reset', '/activiteiten'];

/**
 * The navigation service that handles navigation (forward and back) in the app
 *
 *  We use a custom service to save a history to make sure we can navigate back
 * to the correct page, modal or popover.
 */
@Injectable({
	providedIn: 'root',
})
export class NavigationService {
	private history: Array<INavigationObject> = new Array();
	private lastPoppedHistory: INavigationObject;
	private closing: INavigationObject;
	private routeNavigationSubjects: Array<{
		route: string;
		subject: BehaviorSubject<{ current: INavigationObject; closing?: INavigationObject }>;
	}>;

	constructor(
		private readonly modalController: ModalController,
		private readonly popoverController: PopoverController,
		private readonly router: Router,
		private readonly navigationController: NavController,
		private readonly navigationDataManager: NavigationDataManager,
		private readonly userManager: UserManager
	) {
		this.routeNavigationSubjects = new Array();
	}

	/**
	 * A method used to navigate to a component or page, that enables us to handle navigation data and and returning between closed modals
	 * @param {NavigationType} type The type of the navigation to use
	 * @param _destination The destination to navigate to (either an url or a component)
	 * @param data Data to pass to the component
	 * @param {INavigationOptions} _options The navigation options
	 * @returns A promise that returns possible return data
	 */
	public async navigateTo<Return = any>(
		type: NavigationType,
		_destination: string | ComponentRef,
		data?: { [key in NavigationDataKey]?: any },
		_options: INavigationOptions = baseNavigationOptions
	): Promise<Return> {
		const options = { ...baseNavigationOptions, ..._options };
		const destination = this.addRolePrefix(type, _destination as string);
		this.handleCurrentNavigationObject(options.closePreviousModals);
		this.addToHistory(type, destination, data, options);
		this.addToNavigationData(data);
		switch (type) {
			case NavigationType.modal:
				return this.openModal<Return>(destination);
			case NavigationType.popover:
				return this.openPopover<Return>(destination, (data as any)?.clickEvent);
			case NavigationType.page:
				await this.openPage(destination);
				this.emitNavigationToPage({ current: { type, destination, data, options }, closing: this.closing });
				return Promise.resolve(null) as Promise<Return>;
		}
	}

	/**
	 * A method used to navigate back to the previous component or page.
	 *
	 * When coming from a modal or popover, the modal or popover is closed
	 * @param _data The data that needs to be passed back from a component or popover
	 * @param closePreviousModals Whether or not to close the previous modals/popovers
	 */
	public navigateBack(_data?: { [key: string]: any } | boolean, closePreviousModals: boolean = true): void {
		const current = this.history.pop();
		this.closing = cloneDeep(current);
		switch (current?.type) {
			case NavigationType.modal:
				void (current.modalInstance as HTMLIonModalElement).dismiss(_data).catch();
				break;
			case NavigationType.popover:
				void (current.popoverInstance as HTMLIonPopoverElement).dismiss(_data).catch();
				break;
		}

		if (current.type === NavigationType.page || current.options.closePreviousModals === true) {
			const previous = this.history[this.history.length - 1];
			if (previous && previous.type !== NavigationType.page && current.type !== NavigationType.page) {
				if (this.lastPoppedHistory === previous || closePreviousModals) {
					previous.data = { ...previous.data, ...(_data as object) };
					void this.navigateTo(previous.type, previous.destination, previous.data, {
						addToHistory: false,
					}).catch();
				}
			} else if (!previous) {
				if (!current || current.type === NavigationType.page) {
					this.navigationController.back();
				}
			} else {
				const previousPage = findLast(
					this.history,
					(navigationObject) => navigationObject.type === NavigationType.page
				) || { type: NavigationType.page, destination: '/', options: baseNavigationOptions };
				void this.navigateTo(previousPage.type, previousPage.destination, previousPage.data, {
					addToHistory: false,
				}).catch();
				if (previousPage !== previous) {
					void this.navigateTo(previous.type, previous.destination, previous.data, {
						addToHistory: false,
					}).catch();
				}
			}
		}

		this.lastPoppedHistory = current;
		this.closing = null;
	}

	public isActive(_route: string): boolean {
		const route = this.addRolePrefix(NavigationType.page, _route);
		return this.router.isActive(route, {
			paths: 'subset',
			queryParams: 'subset',
			fragment: 'ignored',
			matrixParams: 'ignored',
		});
	}

	/**
	 * A method that returns the navigation data based on a key
	 * @param _key The key {@link NavigationDataKey} that links to data
	 * @returns The navigation data
	 */
	public getNavigationData<I = any>(_key: NavigationDataKey): I {
		return this.navigationDataManager.getData(_key);
	}

	/**
	 * A method that resets the history chain of the navigation service
	 */
	public resetNavigation(): void {
		this.history = new Array();
	}

	/**
	 * A method that adds the navigation object to the navigation history
	 * @param {NavigationType} type The type of the navigation to use
	 * @param destination The destination to navigate to (either an url or a component)
	 * @param data Data to pass to the component
	 * @param {INavigationOptions} _options The navigation options
	 */
	public addToHistory(
		type: NavigationType,
		destination: string | any,
		data?: { [key in NavigationDataKey]?: any },
		_options: INavigationOptions = baseNavigationOptions
	): void {
		const options = { ...baseNavigationOptions, ..._options };
		if (options.addToHistory) {
			this.history.push({ type, destination, data, options });
		}
	}

	/**
	 * Subscribe to the navigate event when the application navigates to a page
	 * @returns The navigation details
	 */
	public onNavigateToCurrentPage(): BehaviorSubject<{ current: INavigationObject; closing?: INavigationObject }> {
		const existingRouteSubject = this.routeNavigationSubjects.find(
			(routeNavigationSubject) => routeNavigationSubject.route === this.router.url
		);

		if (existingRouteSubject) {
			return existingRouteSubject.subject;
		}

		const routeSubject = { route: this.router.url, subject: new BehaviorSubject(null) };
		this.routeNavigationSubjects.push(routeSubject);
		return routeSubject.subject;
	}

	/**
	 *
	 *
	 * @returns the history object
	 */

	public getReferer() {
		let name = this.history[this.history.length - 2]?.destination.name;
		if (!name) {
			name = this.history[this.history.length - 2]?.destination.replace('/internal/', '');
		}
		return name;
	}

	/**
	 *
	 * @returns true if previous page was an '/internal' page
	 */

	public previousPageInternal() {
		return this.history[this.history.length - 1].destination.includes('/internal');
	}

	/**
	 * Emit navigation details to the subject of the current page
	 * @param navigation The navigation details
	 * @param navigation.current The current navigation object (of the current page)
	 * @param navigation.closing The navigation object of the previous closing page/component
	 */
	private emitNavigationToPage(navigation: { current: INavigationObject; closing?: INavigationObject }): void {
		const existingRouteSubject = this.routeNavigationSubjects.find(
			(routeNavigationSubject) => routeNavigationSubject.route === this.router.url
		);

		if (existingRouteSubject) {
			existingRouteSubject.subject.next(navigation);
		}
	}

	/**
	 * A method that handles closing modals or popovers of the modal or popover when going to navigate to another component or page
	 * @param closePreviousModals Whether or not to close the previously open modal or popover
	 */
	private handleCurrentNavigationObject(closePreviousModals: boolean): void {
		if (closePreviousModals) {
			const current = this.getCurrentNavigationObject();
			switch (current?.type) {
				case NavigationType.modal:
					void (current.modalInstance as HTMLIonModalElement)?.dismiss().catch();
					break;
				case NavigationType.popover:
					void (current.popoverInstance as HTMLIonPopoverElement)?.dismiss().catch();
					break;
			}
		}
	}

	/**
	 * A method that navigates to a page based on an url
	 * @param _url The url to navigate to
	 */
	private async openPage(_url: string): Promise<void> {
		await this.router.navigate([_url]);
	}

	/**
	 * A method that opens a modal based on a component
	 * @param component The component to open as modal
	 * @returns A promise that returns data returned from the component
	 */
	private async openModal<Return>(component: ComponentRef): Promise<Return> {
		return new Promise(async (resolve) => {
			const modal = await this.modalController.create({
				component,
				cssClass: 'auto-height',
				backdropDismiss: false,
			});
			void modal
				.onDidDismiss()
				.then((result) => resolve(result?.data))
				.catch();
			this.addModalToCurrentNavigateObject(modal);
			await modal.present();
		});
	}

	/**
	 * A method that opens a popover based on a component
	 * @param component The component to open as popover
	 * @param _clickEvent The click event to attach the popover to
	 * @returns A promise that returns data returned from the component
	 */
	private async openPopover<Return>(component: ComponentRef, _clickEvent?: PointerEvent): Promise<Return> {
		return new Promise(async (resolve) => {
			const popover = await this.popoverController.create({
				component,
			});
			if (_clickEvent) {
				popover.event = _clickEvent;
			}
			void popover
				.onDidDismiss()
				.then((result) => resolve(result?.data))
				.catch();
			this.addPopoverToCurrentNavigateObject(popover);
			await popover.present();
		});
	}

	/**
	 * A method to add data to the navigation data, using the {@link NavigationDataManager}
	 * @param _data The data to add to the navigation data
	 */
	private addToNavigationData(_data?: { [key in NavigationDataKey]?: any }): void {
		if (_data) {
			Object.keys(_data).forEach((key) => {
				this.navigationDataManager.addData(key as NavigationDataKey, _data[key]);
			});
		}
	}

	/**
	 * A method to get the current (active) navigation object
	 * @returns The current (active) navigation object
	 */
	private getCurrentNavigationObject(): INavigationObject {
		return this.history[this.history.length - 1];
	}

	/**
	 * A method that adds the popover instance to the navigation object that created the popover
	 * @param _popover The popover instance
	 */
	private addPopoverToCurrentNavigateObject(_popover: HTMLIonPopoverElement): void {
		(this.history[this.history.length - 1].popoverInstance as HTMLIonPopoverElement) = _popover;
	}

	/**
	 * A method that adds the modal instance to the navigation object that created the modal
	 * @param _modal The modal instance
	 */
	private addModalToCurrentNavigateObject(_modal: HTMLIonModalElement): void {
		(this.history[this.history.length - 1].modalInstance as HTMLIonModalElement) = _modal;
	}

	/**
	 * Add the correct prefix (internal, client, partner) to the url
	 * @param {NavigationType} type The navigation type
	 * @param _destination The url before adding the prefix
	 * @returns The role prefix
	 */
	private addRolePrefix(type: NavigationType, _destination: string): string {
		if (type === NavigationType.page) {
			if (publicRoutes.includes(_destination)) {
				return _destination;
			}

			let prefix: string = ApplicationModule.Internal;
			if (prefix && !_destination?.includes(prefix)) {
				_destination = `/${prefix}${_destination}`;
			}
		}
		return _destination;
	}
}
