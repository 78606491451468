import { Injectable } from '@angular/core';
import { MessageBoxKey, StorageKey } from '@shared-libs/enums';
import { Observable } from 'rxjs';
import { Preferences } from '@capacitor/preferences';

/**
 * The storage service to handle persistent storage of data as key-value pair
 *
 *  When the client is a web client, local storage is used. When the client is a native client, native storage is used.
 */
@Injectable({
	providedIn: 'root',
})
export class StorageService {
	constructor() {}

	/**
	 * Get an item from persistent storage
	 * @param _key The key linked to the data as {@link StorageKey} {@link MessageBoxKey}
	 * @returns An observable containing the data linked to the _key
	 */
	public getItem<Model>(_key: StorageKey | MessageBoxKey): Observable<Model> {
		return new Observable((subscriber) => {
			Preferences.get({ key: _key })
				.then((result) => {
					subscriber.next(JSON.parse(result.value));
					subscriber.complete();
				})
				.catch(() => {
					subscriber.error({ code: 404, message: `Failed to find ${_key}` });
				});
		});
	}

	/**
	 * Set an item in persistent storage as key-value pair
	 * @param _key The key that has to be linked to the data as {@link StorageKey} {@link MessageBoxKey}
	 * @param _data The data that needs to be stored
	 * @returns An empty observable confirming the storage is successful
	 */
	public setItem(_key: StorageKey | MessageBoxKey, _data: any): Observable<void> {
		return new Observable((subscriber) => {
			Preferences.set({ key: _key, value: JSON.stringify(_data) })
				.then(() => {
					subscriber.next();
					subscriber.complete();
				})
				.catch((err) => subscriber.error(err));
		});
	}

	/**
	 * Remove an item from persistent storage
	 * @param _key The key linked to the data as {@link StorageKey} {@link MessageBoxKey}
	 * @returns An empty observable confirming the storage is successful
	 */
	public removeItem(_key: StorageKey | MessageBoxKey): Observable<void> {
		return new Observable((subscriber) => {
			void Preferences.remove({ key: _key })
				.then(() => {
					subscriber.next();
					subscriber.complete();
				})
				.catch();
		});
	}

	/**
	 * Remove all data from persistent storage
	 */
	public clearAll(): void {
		void Preferences.clear().catch();
	}
}
