import { DatePipe } from '@angular/common';
import { QueryList, reflectComponentType } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { AppModule } from '@app/app.module';
import { TranslateService } from '@ngx-translate/core';
import { IAddress } from '@shared-models/address.model';
import {
	endOfMonth,
	endOfWeek,
	format,
	parseISO,
	setDay,
	startOfMonth,
	startOfWeek,
	subMonths,
	subWeeks,
	startOfQuarter,
	subQuarters,
	endOfQuarter,
	addWeeks,
} from 'date-fns';
import { isString, isNumber, isEmpty } from 'lodash';
import { IDateRange, IFormGroup } from './interfaces';
import { Segment } from '@beego/ngx-segment';
import { ExtendedCalendarView } from '@beego/ngx-calendar';
import { TableDesktopComponent } from '@beego/ngx-table';
import { EventInterval } from '@internal-models/event.model';
import { Action, NavigationType } from './enums';
import { CalendarRecurringChangeComponent } from '@internal-components/calendar-components/calendar-recurring-change-component/calendar-recurring-change.component';
import { NavigationService } from '@shared-services/navigation.service';
import { ValidationService } from '@shared-services/validation.service';
import { IDataQuery } from '@beego/ngx-table';

/**
 * A helper function to encode the data query that is passed to the query params of the api url
 * @param query The {@link IDataQuery} data query
 * @returns An encoded string to include in the URL
 */
export const encodeQueryUrl = (query: Partial<IDataQuery>): string => encodeURIComponent(JSON.stringify(query));

/**
 * Open a separate modal for one-time events and recurring events. Modals check if user is sure about deleting the event.
 * @param interval The event interval this user wants to update or delete
 * @returns Returns true if recurring, false if a singel event must be deleted, null if users cancels deleting the event(s)
 */
export const userWantsRecurringActionOnDeletingEvent = async (interval: EventInterval): Promise<boolean | null> => {
	const validationService: ValidationService = AppModule.injector.get(ValidationService);

	if (interval === EventInterval.OneTime) {
		let removeOneTimeEvent = await validationService.actionIsConfirmed('EVENT');

		// convert response to match the recurring event response
		if (removeOneTimeEvent === false) {
			removeOneTimeEvent = null;
		}
		if (removeOneTimeEvent === true) {
			removeOneTimeEvent = false;
		}
		return removeOneTimeEvent;
	}
	return userWantsRecurringActionOnEvent(interval, Action.DELETE);
};

/**
 * Open a modal to ask whether the user wants the action (update or delete)
 * on the event to be recurring or not.
 * @param interval The event interval this user wants to update or delete
 * @param action The action the user wants to do (update or delete)
 * @returns Whether or not the action needs to be recurring. When the user cancels the
 * action, null is returned.
 */
export const userWantsRecurringActionOnEvent = (interval: EventInterval, action: Action): Promise<boolean> => {
	const navigationService: NavigationService = AppModule.injector.get(NavigationService);
	return new Promise(async (resolve) => {
		if (interval !== EventInterval.OneTime) {
			void navigationService
				.navigateTo(
					NavigationType.popover,
					CalendarRecurringChangeComponent,
					{ action },
					{ closePreviousModals: false }
				)
				.then((result) => {
					if (result !== undefined) {
						resolve(result);
					} else resolve(null);
				})
				.catch();
		} else resolve(false);
	});
};

/**
 * A helper function that checks if a number (as number or string) is a valid number
 * and returns the sanitized number
 * @param value The number as number or string
 * @returns An object containing whether it's valid and the sanitized number
 */
export const validateNumber = (value: number | string): { valid: boolean; value: number } => {
	if (isString(value) && (value as string).replace(/ /g, '').length > 0) {
		value = Number(value);
	}

	return { valid: isNumber(value), value: value as number };
};

/**
 * A helper function to shorten a name of a person
 * Ex. Jos Van der Meulen -> Jos V. d. M.
 * @param firstname The firstname
 * @param lastname Then lastname
 * @returns A shortened name string
 */
export const getShortNameString = (firstname: string, lastname: string): string =>
	`${firstname} ${lastname
		.split(' ')
		.map((lastname) => lastname.slice(0, 1))
		.join('. ')}.`;

/**
 * A helper function to format a {@link IAddress} object into a string
 * @param address The address that needs to be formatted into a string
 * @returns The formatted address as string
 */
export const getAddressString = (address: IAddress | null): string =>
	address && address.street
		? `${address.street} ${address.number}${address.postbox ? ` ${address.postbox}` : ''}, ${address.zipcode} ${
				address.city
			}`
		: null;

export const addressesMatch = (address1: IAddress | null, address2: IAddress | null): boolean =>
	address1 &&
	address2 &&
	address1.street === address2.street &&
	address1.number === address2.number &&
	address1.postbox === address2.postbox &&
	address1.country === address2.country &&
	address1.city === address2.city &&
	address1.zipcode === address2.zipcode;

/**
 * A helper function to check whether or not a form is valid
 * Marks the entire formgroup as touched to show possible validation errors
 * @param formGroup The formgroup to check
 * @returns whether or not a form is valid
 * @param componentClass class of the component that triggered the action
 */
export const formIsValid = (formGroup: IFormGroup, componentClass?: any): boolean => {
	markFormGroupTouched(formGroup);
	scrollFirstValidationErrorIntoView(componentClass);
	return formGroup.valid;
};

/**
 * Marks all controls in a form group as touched
 * This is relevant to show the validation errors
 * @param formGroup - The form group to touch
 */
export const markFormGroupTouched = (formGroup: FormGroup | AbstractControl): void => {
	if (formGroup) {
		Object.values((formGroup as any).controls).forEach((control: FormGroup) => {
			control.markAsTouched();
			if (control.controls) {
				markFormGroupTouched(control);
			}
		});
	}
};

/**
 * Get all fields that are not valid
 * @param formGroup - The form group
 * @returns {Array<{ field: string; errors: Array<string> }>} The array with fields and errors
 */
export const getInvalidFields = (
	formGroup: FormGroup | AbstractControl
): Array<{ field: string; errors: Array<string> }> => {
	const fields = new Array();
	if (formGroup) {
		Object.values((formGroup as any).controls).forEach((control: FormGroup) => {
			if (control.controls) {
				getInvalidFields(control);
			} else if (!control.valid) {
				const parentFormgroup = control.parent.controls;
				fields.push({
					field: Object.keys(parentFormgroup).find((name) => control === parentFormgroup[name]),
					errors: control.errors,
				});
			}
		});
	}
	return fields;
};

/**
 * Duplicate form fields value on change
 * @param form The form
 * @param key1 The key of field 1
 * @param key2 The key of field 2
 */
export const duplicateFormFieldValues = (form: FormGroup, key1: string, key2: string): void => {
	form.get(key1).valueChanges.subscribe(async () => {
		if (
			form.get(key1).valid &&
			(isEmpty(form.get(key2).value) ||
				(form.get(key1).value as string)?.slice(0, -1) === form.get(key2).value ||
				(form.get(key1).value as string).includes(form.get(key2).value))
		) {
			form.get(key2).patchValue(form.get(key1).value, { emitEvent: false });
		}
	});
	form.get(key2).valueChanges.subscribe(async () => {
		if (
			form.get(key2).valid &&
			(isEmpty(form.get(key1).value) ||
				(form.get(key2).value as string)?.slice(0, -1) === form.get(key1).value ||
				(form.get(key2).value as string).includes(form.get(key1).value))
		) {
			form.get(key1).patchValue(form.get(key2).value, { emitEvent: false });
		}
	});
};

/**
 * A helper method to check whether a form has any keys set
 *
 * can be used when checking whether it needs to be autofilled,
 * or not when any value is inputted by the user
 * @param form The form
 * @param ignoredKeys The keys to be ignored
 * @returns Whether the form has any filled in inputs
 */
export const formHasSetKeys = (form: FormGroup, ignoredKeys?: Array<string>): boolean => {
	for (const key of Object.keys(form.value)) {
		if ((form.get(key) as FormGroup).controls) {
			return formHasSetKeys(form.get(key) as FormGroup, ignoredKeys);
		} else if (!isEmpty(form.get(key).value) && !ignoredKeys.includes(key)) {
			return true;
		}
	}
	return false;
};

/**
 * A helper method to scroll the first input into view that has a validation error
 * @param componentClass class of the component that triggered the action
 */
export const scrollFirstValidationErrorIntoView = (componentClass?: any): void => {
	let componentSelector: string;
	if (componentClass) {
		const metadata = reflectComponentType(componentClass);
		componentSelector = metadata.selector;
	}
	const query: string = `${componentSelector ?? ''} .ng-invalid:not(form, form-client)`;
	if (document.querySelector(query)) {
		document.querySelector(query).scrollIntoView({ behavior: 'smooth' });
	}
};

/**
 * A helper method to translate the name of segments
 * @param segments The segments
 * @returns The translated segments
 */
export const translateSegments = (segments: Array<Segment>): Array<Segment> => {
	const translateService: TranslateService = AppModule.injector.get(TranslateService);

	return segments.map((segment) =>
		typeof segment === 'string'
			? (segment = translateService.instant(`SEGMENTS.${segment}`))
			: (segment.name = translateService.instant(`SEGMENTS.${segment.name}`))
	);
};

/**
 * A helper method to translate a key into a string
 * @param key The translation key
 * @param params The translation params
 * @returns The translated string
 */
export const translate = (key: string, params?: Object): string => {
	const translateService: TranslateService = AppModule.injector.get(TranslateService);
	return translateService.instant(key, params);
};

/**
 * A helper method to get the name of a segment as string
 * @param segment The segment to get the name of
 * @returns {string} The segment name
 */
export const getSegmentName = (segment: Segment): string => (typeof segment === 'string' ? segment : segment?.name);

/**
 * A helper method to refresh data display component when loaded in
 * @param tableComponent The data display component to refresh
 * @param dataDisplayComponents All loaded data display components
 */
export const addDataDisplayRefreshListener = (
	tableComponent: TableDesktopComponent,
	dataDisplayComponents: QueryList<TableDesktopComponent>
): void => {
	if (dataDisplayComponents.length > 0) {
		tableComponent = dataDisplayComponents.first;
		tableComponent.refreshData();
	}
	dataDisplayComponents.changes.subscribe((comps: QueryList<TableDesktopComponent>) => {
		tableComponent = comps.first;
		comps.first?.refreshData();
	});
};

/**
 * @static
 */
export class DateHelper {
	/**
	 * Format a date to the correct timezone
	 * @param date The date
	 * @returns The corrected date
	 */
	public static correctTimezone(date: Date | string): Date {
		return new Date(new DatePipe('nl-be').transform(date, 'yyyy-MM-dd HH:mm:ss', 'Europe/Brussels'));
	}

	/**
	 * A function that returns a {@link IDateRange} object of the previous week
	 * @returns The {@link IDateRange} object
	 */
	public static getPreviousWeek(): IDateRange {
		const lastMonday: Date = subWeeks(setDay(new Date(), 1), 1);
		const lastSunday: Date = endOfWeek(lastMonday, { weekStartsOn: 1 });
		return { startDate: format(lastMonday, 'yyyy-MM-dd'), endDate: format(lastSunday, 'yyyy-MM-dd') };
	}

	/**
	 * A function that returns a {@link IDateRange} object of the previous week
	 * @returns The {@link IDateRange} object
	 */
	public static getPrevious2Weeks(): IDateRange {
		const lastMonday: Date = subWeeks(setDay(new Date(), 1), 2);
		const lastSunday: Date = addWeeks(endOfWeek(lastMonday, { weekStartsOn: 1 }), 1);
		return { startDate: format(lastMonday, 'yyyy-MM-dd'), endDate: format(lastSunday, 'yyyy-MM-dd') };
	}

	/**
	 * A function that returns a {@link IDateRange} object of the previous month
	 * @returns The {@link IDateRange} object
	 */
	public static getPreviousMonth(): IDateRange {
		const beginOfLastMonth: Date = startOfMonth(subMonths(new Date(), 1));
		const endOfLastMonth: Date = endOfMonth(beginOfLastMonth);
		return { startDate: format(beginOfLastMonth, 'yyyy-MM-dd'), endDate: format(endOfLastMonth, 'yyyy-MM-dd') };
	}

	/**
	 * A function that returns a {@link IDateRange} object of the previous quarter
	 * @returns The {@link IDateRange} object
	 */
	public static getPreviousQuarter(): IDateRange {
		const beginOfLastQuarter: Date = startOfQuarter(subQuarters(new Date(), 1));
		const endOfLastQuarter: Date = endOfQuarter(beginOfLastQuarter);
		return { startDate: format(beginOfLastQuarter, 'yyyy-MM-dd'), endDate: format(endOfLastQuarter, 'yyyy-MM-dd') };
	}

	/**
	 * A function that returns a {@link IDateRange} object of the current month
	 * @returns The {@link IDateRange} object
	 */
	public static getCurrentMonth(): IDateRange {
		const beginOfCurrentMonth: Date = startOfMonth(new Date());
		const endOfCurrentMonth: Date = endOfMonth(beginOfCurrentMonth);
		return {
			startDate: format(beginOfCurrentMonth, 'yyyy-MM-dd'),
			endDate: format(endOfCurrentMonth, 'yyyy-MM-dd'),
		};
	}

	/**
	 * A helper function to format a {@link IDateRange} object into a string
	 * @param dateRange The date range that needs to be formatted into a string
	 * @returns The formatted date range as string
	 */
	public static getDateRangeString(dateRange: IDateRange): string {
		return `${format(new Date(dateRange.startDate), 'd/M/yyyy')} - ${format(
			new Date(dateRange.endDate),
			'd/M/yyyy'
		)}`;
	}

	/**
	 * A helper function to format a date parameter that could be a string but needs to be a Date
	 * @param date The date as string or date
	 * @returns The formatted date as Date
	 */
	public static parseDate(date: string | Date): Date {
		if (typeof date === 'string') {
			return parseISO(date as string);
		}
		return date;
	}

	/**
	 * A helper function to format a date parameter that could be a string but needs to be a Date
	 * @param _date The date as string or date
	 * @param time The time as string
	 * @returns The formatted date as Date
	 */
	public static generateDate(_date: string, time: string): Date {
		const date = new Date(_date);
		date.setHours(parseInt(time.slice(0, 2)), parseInt(time.slice(3, 5)), parseInt(time.slice(6, 8)));
		return date;
	}

	/**
	 * A helper function that gets the daterange of a date based on the view of the calendar
	 * @param viewDate The current date
	 * @param view The current view
	 * @returns The correct daterange for that date and view
	 */
	public static getDateRange(viewDate: Date, view: ExtendedCalendarView): IDateRange {
		switch (view) {
			case ExtendedCalendarView.Month:
				return {
					startDate: format(startOfMonth(viewDate), 'yyyy-MM-dd'),
					endDate: format(endOfMonth(viewDate), 'yyyy-MM-dd'),
				};
			case ExtendedCalendarView.Week:
			case ExtendedCalendarView.WorkWeek:
				return {
					startDate: format(startOfWeek(viewDate, { weekStartsOn: 1 }), 'yyyy-MM-dd'),
					endDate: format(endOfWeek(viewDate, { weekStartsOn: 1 }), 'yyyy-MM-dd'),
				};
			case ExtendedCalendarView.Day:
				return {
					startDate: format(viewDate, 'yyyy-MM-dd'),
					endDate: format(viewDate, 'yyyy-MM-dd'),
				};
		}
	}
}
