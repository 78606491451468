import { StorageService } from '@shared-services/storage.service';
import { StorageKey } from '@shared-libs/enums';
import { UserManager } from './user.manager';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

/* TODO: replace managers with redux strategy? */

/**
 * The manager for the user details objects.
 * A Manager is responsible for the state and reusability
 * (across the application) of certain properties
 *
 * Based on the role of the logged in user,
 * The user details can be different.
 *
 */
@Injectable({
	providedIn: 'root',
})
export class UserDetailsManager<Model = any> {
	private userDetails: Model;
	private subject: BehaviorSubject<Model>;

	constructor(
		protected readonly userManager: UserManager,
		protected readonly storageService: StorageService
	) {
		this.initSubject();
	}

	/**
	 * Set the user details
	 * @param userDetails The user details
	 */
	public setUserDetails(userDetails: Model): void {
		this.subject.next(userDetails);
		this.userDetails = userDetails;
		this.storeUserDetails();
	}

	/**
	 * Set attribute of the user details
	 * @param attribute The attribute to set
	 * @param value The value to assign to the attribute
	 */
	public setUserDetailsAttribute(attribute: string, value: any): void {
		this.userDetails[attribute] = value;
		this.storeUserDetails();
	}

	/**
	 * Return the user details
	 * @returns the user details
	 */
	public getUserDetails(): BehaviorSubject<Model> {
		return this.subject;
	}

	/**
	 * Load the user details from persistent storage and save it in session storage
	 */
	public loadUserDetails(): void {
		this.storageService.getItem<Model>(StorageKey.userDetails).subscribe((userDetails) => {
			this.userDetails = userDetails;
		});
	}

	/**
	 * Check whether the user details are loaded or not
	 * @returns whether the user details are loaded or not
	 */
	public userDetailsAreLoaded(): boolean {
		return !!this.userDetails;
	}

	/**
	 * Remove the user details from session and persistent storage
	 */
	public removeUserDetails(): void {
		this.userDetails = null;
		this.storageService.removeItem(StorageKey.userDetails).subscribe();
	}

	/**
	 * Store the user details from session storage in persistent storage
	 */
	private storeUserDetails(): void {
		this.storageService.setItem(StorageKey.userDetails, this.userDetails).subscribe(() => {});
	}

	private initSubject(): void {
		if (!this.subject) {
			this.subject = new BehaviorSubject(null);
		}
	}
}
