import { Injectable } from '@angular/core';
import { NavigationDataKey } from '@shared-libs/enums';
import { cloneDeep } from 'lodash';

/* TODO: replace managers with redux strategy? */

type NavigationData = string | number | { [key: string]: any };

/**
 * The manager for the navigation data object. This manager is used to pass through objects, strings and/or numbers when routing through the application.
 *
 *  The navigation data object is a object to which you can add properties (conform the {@link NavigationDataKey}).
 * The properties are available accross the application, and are deleted from the object when the getData method is called.
 */
@Injectable({
	providedIn: 'root',
})
export class NavigationDataManager {
	private navigationData: { [key in NavigationDataKey]?: NavigationData } = {};

	/**
	 * Add data to the navigation data property, which enables it to get the data accross the entire application.
	 * @param {NavigationDataKey} key The key to store the data
	 * @param {NavigationData} data The data to store
	 */
	public addData(key: NavigationDataKey, data: NavigationData): void {
		this.navigationData = { ...this.navigationData, ...{ [key]: data } };
	}

	/**
	 * Get data by the NavigationDateKey from the navigation data
	 *
	 * It is possible to pass a type of the returning data. When getting data, the data is removed from the navigation data property
	 * @param {NavigationDataKey} key The key linked to the data when adding the data
	 * @returns The data linked to the key
	 */
	public getData<Model = NavigationData>(key: NavigationDataKey): Model {
		const data = cloneDeep(this.navigationData[key]) as any as Model;
		delete this.navigationData[key];
		return data;
	}

	/**
	 * Checks is certain data is set
	 * @param {NavigationDataKey} key The key for which the data has to be set
	 * @returns Whether the key and data is set or not
	 */
	public dataIsSet(key: NavigationDataKey): boolean {
		return this.navigationData[key] != null;
	}

	/**
	 * Flush the entire navigation data store
	 */
	public flush(): void {
		this.navigationData = {};
	}
}
