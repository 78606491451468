/* eslint-disable prettier/prettier */
export const VALIDATION = {
	EMAIL_REGEX: new RegExp('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+.([a-zA-Z0-9-]+.)*[a-zA-Z0-9-]{2,}$'),
	STRING_REGEX: new RegExp('[^]+'),
	NAME_REGEX: new RegExp("^([A-zÀ-úäöüÄÖÜâêûîôÂÊÛÎÔ' .-]+)?([A-zÀ-úäöüÄÖÜâêûîôÂÊÛÎÔ .-]+)$"),
	PHONE_REGEX: new RegExp('^([+0-9./ ]{9,18})$'),
	ZIPCODE_REGEX: new RegExp('^[1-9]{1}[0-9]{3}$'),
	BIRTHYEAR_REGEX: new RegExp('^(19|20)[0-9]{2}$'),
	TIME_REGEX: /^(?:(?:([01]?\d|2[0-3]):)?([0-5]?\d):)?([0-5]?\d)$/,
	// eslint-disable-next-line max-len
	VAT_REGEX: new RegExp(
		'^((AT)?U[0-9]{8}|(BE)?0[0-9]{9}|(BG)?[0-9]{9,10}|(CY)?[0-9]{8}L|(CZ)?[0-9]{8,10}|(DE)?[0-9]{9}|(DK)?[0-9]{8}|(EE)?[0-9]{9}|(EL|GR)?[0-9]{9}|(ES)?[0-9A-Z][0-9]{7}[0-9A-Z]|(FI)?[0-9]{8}|(FR)?[0-9A-Z]{2}[0-9]{9}|(GB)?([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})|(HU)?[0-9]{8}|(IE)?[0-9]S[0-9]{5}L|(IT)?[0-9]{11}|(LT)?([0-9]{9}|[0-9]{12})|(LU)?[0-9]{8}|(LV)?[0-9]{11}|(MT)?[0-9]{8}|(NL)?[0-9]{9}B[0-9]{2}|(PL)?[0-9]{10}|(PT)?[0-9]{9}|(RO)?[0-9]{2,10}|(SE)?[0-9]{12}|(SI)?[0-9]{8}|(SK)?[0-9]{10})$'
	),
	DATE_STRING: /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/,
	ALTERNATE_DATE_STRING: /(^0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/(\d{4}$)/,
	INPUT_DATE_STRING: /((0[1-9]|[12]\d|3[01])(-|\/)(0[1-9]|1[0-2])(-|\/)([12]\d{3}))/,
	COUNTRY_CODE: new RegExp('^[A-Z]{2}$'),
	COLOR_HEX: new RegExp('^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$'),
	SOCIAL_REGEX: new RegExp('^[0-9]{2}[.]{0,1}[0-9]{2}[.]{0,1}[0-9]{2}[-]{0,1}[0-9]{3}[.]{0,1}[0-9]{2}$'),
	URL_REGEX: /^(?:(?:ht|f)tp(?:s?)\:\/\/|~\/|\/)?(?:\w+:\w+@)?((?:(?:[-\w\d{1-3}]+\.)+(?:com|org|net|gov|mil|biz|info|mobi|name|aero|jobs|edu|co\.uk|ac\.uk|it|fr|tv|museum|asia|local|travel|[a-z]{2}))|((\b25[0-5]\b|\b[2][0-4][0-9]\b|\b[0-1]?[0-9]?[0-9]\b)(\.(\b25[0-5]\b|\b[2][0-4][0-9]\b|\b[0-1]?[0-9]?[0-9]\b)){3}))(?::[\d]{1,5})?(?:(?:(?:\/(?:[-\w~!$+|.,=]|%[a-f\d]{2})+)+|\/)+|\?|#)?(?:(?:\?(?:[-\w~!$+|.,*:]|%[a-f\d{2}])+=?(?:[-\w~!$+|.,*:=]|%[a-f\d]{2})*)(?:&(?:[-\w~!$+|.,*:]|%[a-f\d{2}])+=?(?:[-\w~!$+|.,*:=]|%[a-f\d]{2})*)*)*(?:#(?:[-\w~!$ |\/.,*:;=]|%[a-f\d]{2})*)?$/i,
	UUID: /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/i

};

export const TEMPLATE_WIDTH_CHANGE = 768;
