import { Component } from '@angular/core';
import { Action, NavigationDataKey } from '@shared-libs/enums';
import { NavigationService } from '@shared-services/navigation.service';
@Component({
	selector: 'calendar-recurring-change-component',
	templateUrl: 'calendar-recurring-change.component.html',
	styleUrls: ['./calendar-recurring-change.component.scss'],
})
export class CalendarRecurringChangeComponent {
	public readonly action: Action;
	public readonly Action: typeof Action = Action;

	constructor(private readonly navigationService: NavigationService) {
		this.action = this.navigationService.getNavigationData(NavigationDataKey.action);
	}

	public answer(recurring: boolean): void {
		this.navigationService.navigateBack(recurring);
	}

	public cancel(): void {
		this.navigationService.navigateBack();
	}
}
