import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './modules/shared/guards/auth.guard';

export const routes: Routes = [
	{
		path: 'internal',
		loadChildren: () => import('./modules/internal/internal.module').then((m) => m.InternalModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'login',
		loadChildren: () =>
			import('./modules/public/login/gui/pages/login-page/login.module').then((m) => m.LoginPageModule),
	},
	{
		path: 'register',
		loadChildren: () =>
			import('./modules/public/register/gui/pages/register-page/register.module').then(
				(m) => m.RegisterPageModule
			),
	},
	{
		path: 'digibank-registreren',
		loadChildren: () =>
			import('./modules/public/register-tenant/gui/pages/register-page/register-tenant.module').then(
				(m) => m.RegisterTenantPageModule
			),
	},
	{
		path: 'reset',
		loadChildren: () =>
			import('./modules/public/login/gui/pages/reset-page/reset.module').then((m) => m.ResetPageModule),
	},
	{
		path: 'activiteiten',
		loadChildren: () =>
			import('./modules/public/public-events/gui/pages/public-events-page/public-events.module').then(
				(m) => m.PublicEventsPageModule
			),
	},
	{
		path: '',
		loadChildren: () =>
			import('./modules/public/landing/gui/pages/landing-page/landing.module').then((m) => m.LandingPageModule),
	},

	// { path: '', redirectTo: 'login', pathMatch: 'full' },
	// { path: '**', redirectTo: 'login' },
	{ path: '**', redirectTo: '' },
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, useHash: false })],
	exports: [RouterModule],
})
export class AppRoutingModule {}
