<div class="_error-component">
    <div class="_error-container">
        <img class="_error-image" src="./assets/imgs/foutmelding.png" />

        <ng-container *ngIf="!isValidation">
            <p class="_error-title" [translate]="'ERROR.TITLE'"></p>

            <div class="_error-message-container">
                <p class="_error-message-title" [translate]="'ERROR.MESSAGE_TITLE'"></p>
                <p *ngIf="error.status !== 0" class="_error-message">{{error.message}}</p>
                <p *ngIf="error.status === 0" class="_error-message">{{'ERROR.NO_INTERNET' | translate}}</p>
            </div>
        </ng-container>

        <ng-container *ngIf="isValidation">
            <p class="_error-title" [translate]="'ERROR.VALIDATION_TITLE'"></p>

            <div class="_error-message-container">
                <p class="_error-message-title" [translate]="'ERROR.VALIDATION_MESSAGE_TITLE'"></p>
                <p class="_error-message">{{error.message}}</p>
            </div>
        </ng-container>

        <div class="_buttons">
            <button (click)="close()" [translate]="'BUTTONS.OK'" class="_button _close-button" ></button>
        </div>
    </div>
</div>