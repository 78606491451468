import { Injectable, Injector } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { Api } from './api.provider';
import { UserManager } from '@app/modules/shared/managers/user.manager';
import jwt_decode from 'jwt-decode';
import { environment as ENV } from '@environments/environment';
import { AuthenticationService } from '@shared-services/authentication.service';

/**
 * An Angular interceptor that adds the required headers to the request and handles refresh token logic
 * @augments HttpInterceptor
 */
@Injectable()
export class Interceptor implements HttpInterceptor {
	private isRefreshingToken: boolean = false;
	private readonly refreshTokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

	constructor(
		private readonly injector: Injector,
		private readonly userManager: UserManager,
		private readonly api: Api
	) {}

	/**
	 * Intercept the request before sending it, and add the headers when sending requests to the API.
	 * When the API Authorization token is expired and the request is to the API,
	 * the refresh logic is used to re-authenticate.
	 * @param _request  The HTTP request
	 * @param next The next handler for the request
	 * @returns An observable containing the HttpEvent with the request result or error
	 */
	public intercept(_request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		let request = _request;

		if (_request.url.includes(ENV.BASE_URL) && !_request.url.includes('users/refresh')) {
			const token = this.userManager.getToken();

			if (token && !request.headers.has('type')) {
				const { exp } = jwt_decode(token) as { exp: number };

				if (new Date(exp * 1000) < new Date()) {
					return this.refreshToken(request, token, next);
				}

				request = this.setHeaders(request, token);
			} else if (request.headers.has('type')) {
				request = request.clone({ headers: request.headers.delete('type', 'external') });
			}

			return next.handle(request).pipe(
				catchError((err) => {
					if (err.status === 498) {
						this.refreshToken(request, token, next);
					}
					return throwError(err);
				})
			);
		}

		return next.handle(request);
	}

	private setHeaders(_request: HttpRequest<any>, _token: string): HttpRequest<any> {
		return _request.clone({
			setHeaders: {
				Authorization: `Bearer ${_token}`,
			},
		});
	}

	/**
	 * When not in process of a request to refresh the access token, we do a request to refresh the access token
	 * and when finished we process the initial request. When we are in process of refreshing, the initial request is piped
	 * to execute after the refresh is processed. No double refreshes are done.
	 * @param request The initial request
	 * @param token The expired token
	 * @param next The httpHandler
	 * @returns An observable with the result of the request as result
	 */
	private refreshToken(request: HttpRequest<any>, token: string, next: HttpHandler): Observable<any> {
		if (!this.isRefreshingToken) {
			this.isRefreshingToken = true;
			this.refreshTokenSubject.next(null);

			return this.api
				.post('/users/refresh', { refresh_token: this.userManager.getRefreshToken() }, true)
				.pipe(
					switchMap((result) => {
						token = (result.data as any).access_token;
						this.refreshTokenSubject.next(token);
						this.userManager.saveToken(token);
						request = this.setHeaders(request, token);
						this.isRefreshingToken = false;
						return next.handle(request);
					})
				)
				.pipe(
					catchError((err) => {
						if (err.status === 401) {
							this.injector.get(AuthenticationService).logout();
						}
						return throwError(err);
					})
				);
		} else {
			return this.refreshTokenSubject.pipe(
				filter((token) => token != null),
				take(1),
				switchMap((_token) => next.handle(this.setHeaders(request, _token)))
			);
		}
	}
}
