import { Injectable } from '@angular/core';
import { ValidationService } from './validation.service';

/**
 * The service that handles the background color of HTML needed for mobile devices with a notch
 */
@Injectable({
	providedIn: 'root',
})
export class BackgroundColorService {
	private top: string;
	private bottom: string;
	private previousTop: string;
	private previousBottom: string;

	constructor(private readonly validationService: ValidationService) {}

	public setBackgroundColor(top: string, bottom?: string): void {
		if (this.validationService.isOnMobile()) {
			const html = (window as any).html;
			if (html) {
				this.top = top;
				this.bottom = bottom || 'rgba(255,255,255,1)';
				html.style.background = `linear-gradient(180deg, ${this.top} 50%, ${this.bottom} 50%)`;
			}
		}
	}

	public resetBackgroundColor(): void {
		if (this.validationService.isOnMobile()) {
			const html = (window as any).html;
			if (html) {
				this.previousTop = this.top;
				this.previousBottom = this.bottom;
				html.style.background = 'linear-gradient(180deg, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 50%)';
			}
		}
	}

	public setPreviousBackgroundColor(): void {
		this.setBackgroundColor(this.previousTop, this.previousBottom);
	}

	public removeBackgroundColor(): void {
		this.setBackgroundColor('transparent', 'transparent');
		this.top = null;
		this.bottom = null;
		this.previousTop = null;
		this.previousBottom = null;
	}
}
