import { Injectable } from '@angular/core';
import { Platform, PopoverController } from '@ionic/angular';
import { ConfirmationComponent } from '@shared-components/confirmation-component/confirmation.component';
import { TEMPLATE_WIDTH_CHANGE } from '@shared-libs/constants';

/**
 * The service to validate data and environments
 */
@Injectable({
	providedIn: 'root',
})
export class ValidationService {
	constructor(
		private readonly platform: Platform,
		private readonly popoverController: PopoverController
	) {}

	/**
	 * Validate whether a the user device is a cordova enabled device (mobile or tablet)
	 * @returns Whether the user device is cordova or capacitor enabled
	 */
	public isOnMobile(): boolean {
		return this.platform.is('capacitor') || this.platform.is('cordova');
	}

	/**
	 * Validate whether a the user device is a mobile device
	 * @returns Whether the user device is mobile
	 */
	public isOnMobileTemplate(): boolean {
		return window.innerWidth < TEMPLATE_WIDTH_CHANGE;
	}

	/**
	 * Validate whether a the user device is a native iOS device
	 * @returns Whether the user device is a native iOS device
	 */
	public isOnNativeiOS(): boolean {
		return this.isOnMobile() && this.platform.is('ios');
	}

	/**
	 * Show the confirmation component to confirm an action (ex. deleting something)
	 * @param messageKey The translation key for the action
	 * @returns Whether the action is confirmed or not
	 */
	public actionIsConfirmed(messageKey: string): Promise<boolean> {
		return new Promise(async (resolve) => {
			const popover = await this.popoverController.create({
				component: ConfirmationComponent,
				componentProps: { message: messageKey },
			});
			void popover
				.onDidDismiss()
				.then((result) => {
					if (result.data !== undefined) {
						resolve(result.data);
					} else resolve(false);
				})
				.catch();
			await popover.present();
		});
	}
}
