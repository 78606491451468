import { PathLocationStrategy, APP_BASE_HREF, PlatformLocation } from '@angular/common';
import { Optional, Inject, Injectable } from '@angular/core';
import { NavigationDataManager } from '@shared-managers/navigation-data.manager';
import { NavigationDataKey } from './enums';
@Injectable()
export class PathPreserveQueryLocationStrategy extends PathLocationStrategy {
	constructor(
		private readonly platformLocation: PlatformLocation,
		private readonly navigationDataManager: NavigationDataManager,
		@Optional() @Inject(APP_BASE_HREF) baseHref?: string
	) {
		super(platformLocation, baseHref);
	}

	private get search(): string {
		return this.platformLocation?.search ?? '';
	}

	public prepareExternalUrl(internal: string): string {
		const path = super.prepareExternalUrl(internal);
		const existingURLSearchParams = new URLSearchParams(this.search);
		const params = Object.fromEntries((existingURLSearchParams as any).entries());
		this.formatAndStoreParams(params);
		return path;
	}

	private formatAndStoreParams(params: object): void {
		const currentParams: object = this.navigationDataManager.getData(NavigationDataKey.queryParams) || {};
		this.navigationDataManager.addData(NavigationDataKey.queryParams, { ...currentParams, ...params });
	}
}
