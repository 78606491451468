import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimePipe } from './time.pipe';
import { AddressPipe } from './address.pipe';
import { PhonePipe } from './phone.pipe';
import { CurrencyPipe } from './currency.pipe';
import { ArrayValuePipe } from './array-value.pipe';
import { ReplacePipe } from './replace.pipe';
import { DateRangePipe } from './date-range.pipe';
import { HoursAndMinutesPipe } from './hours-and-minutes.pipe';
import { HoursPipe } from './hours.pipe';
import { MinutesPipe } from './minutes.pipe';
import { OrderByPipe } from './order-by.pipe';
import { SumPipe } from './sum.pipe';
import { CastPipe } from './cast.pipe';
import { FilterPipe } from './filter.pipe';
import { shortenNamePipe } from './shorten-name.pipe';
import { IncludesPipe } from './includes.pipe';
import { OrganizerNamePipe } from './organizer-name.pipe';
@NgModule({
	declarations: [
		TimePipe,
		AddressPipe,
		PhonePipe,
		CurrencyPipe,
		ArrayValuePipe,
		ReplacePipe,
		DateRangePipe,
		HoursAndMinutesPipe,
		HoursPipe,
		MinutesPipe,
		OrderByPipe,
		SumPipe,
		CastPipe,
		FilterPipe,
		shortenNamePipe,
		IncludesPipe,
		OrganizerNamePipe,
	],
	imports: [CommonModule],
	exports: [
		TimePipe,
		AddressPipe,
		PhonePipe,
		CurrencyPipe,
		ArrayValuePipe,
		ReplacePipe,
		DateRangePipe,
		HoursAndMinutesPipe,
		HoursPipe,
		MinutesPipe,
		OrderByPipe,
		SumPipe,
		CastPipe,
		FilterPipe,
		shortenNamePipe,
		IncludesPipe,
		OrganizerNamePipe,
	],
})
export class PipesModule {}
